let crmInstance = ''
let gatewayClientId = ''
let aetherClient = ''
let crmGatewayUrl = ''
let aetherInfoApiUrl = ''
let aetherInfoClientId = ''
let currentUsersAPIUrl = ''
let currentUsersClientId = ''
let appInsights = ''

switch (window.location.origin) {
  // LOCAL
  case "http://localhost:8080":
  case "http://localhost:8081":
    crmInstance = 'https://lucrmdev.crm.dynamics.com';
    gatewayClientId = 'd53de251-a0cf-4808-857e-a2b70fb87f53';
    aetherClient = 'b023ae3c-83c8-4ac5-927a-31ec3cc63552';
    crmGatewayUrl = 'https://localhost:44300';
    aetherInfoApiUrl = 'https://localhost:7155';
    aetherInfoClientId = 'edd796ec-4607-451c-9d9f-dcded700bd1f';
    currentUsersAPIUrl = 'https://websvcdev.azure-api.net/currentusersapi';
    currentUsersClientId = 'adaacddf-622d-43a7-8c89-43e7d383baac';
    appInsights = '271d4ea1-2c54-4eab-aee5-123e6291683e';
    break;
  // DEV 
  case "https://purple-moss-006a7d40f-dev.eastus2.3.azurestaticapps.net":
    crmInstance = 'https://lucrmdev.crm.dynamics.com';
    gatewayClientId = 'd53de251-a0cf-4808-857e-a2b70fb87f53';
    aetherClient = 'b023ae3c-83c8-4ac5-927a-31ec3cc63552';
    crmGatewayUrl = 'https://crmgateway2-sandbox-dev.azurewebsites.net';
    aetherInfoApiUrl = 'https://aetherapi-development.azurewebsites.net';
    aetherInfoClientId = 'edd796ec-4607-451c-9d9f-dcded700bd1f';
    currentUsersAPIUrl = 'https://websvcdev.azure-api.net/currentusersapi';
    currentUsersClientId = 'adaacddf-622d-43a7-8c89-43e7d383baac';
    appInsights = '271d4ea1-2c54-4eab-aee5-123e6291683e';
    break;
  // TEST
  case "https://purple-moss-006a7d40f-test.eastus2.3.azurestaticapps.net":
    crmInstance = 'https://lucrmtest.crm.dynamics.com';
    gatewayClientId = 'd53de251-a0cf-4808-857e-a2b70fb87f53';
    aetherClient = 'b023ae3c-83c8-4ac5-927a-31ec3cc63552';
    crmGatewayUrl = 'https://crmgateway2-sandbox.azurewebsites.net';
    aetherInfoApiUrl = 'https://aetherapi-development.azurewebsites.net';
    aetherInfoClientId = 'edd796ec-4607-451c-9d9f-dcded700bd1f';
    currentUsersAPIUrl = 'https://test.apis.liberty.edu/currentusersapi';
    currentUsersClientId = 'adaacddf-622d-43a7-8c89-43e7d383baac';
    appInsights = '271d4ea1-2c54-4eab-aee5-123e6291683e';
    break;
  // PROD
  case "https://aether.liberty.edu":
  case "https://relm.liberty.edu":
    crmInstance = 'https://libertyuniv.crm.dynamics.com';
    gatewayClientId = '2e243288-bc1e-4839-b527-b0c0e65e0a77';
    aetherClient = '2ddbe078-a1ca-4a10-82ed-23a4a793526e';
    crmGatewayUrl = 'https://crmgateway2.azurewebsites.net';
    aetherInfoApiUrl = 'https://aetherapi-production.azurewebsites.net';
    aetherInfoClientId = '48b5d877-a83e-4d9b-b29a-bfcc0725977b';
    currentUsersAPIUrl = 'https://apis.liberty.edu/currentusersapi';
    currentUsersClientId = '18201576-1881-48f3-b9db-740572245212';
    appInsights = 'e8ecbb2a-c61a-4d76-b806-f47d72a84362';
    break;
  default:
    crmInstance = "https://lucrmdev.crm.dynamics.com";
    gatewayClientId = 'd53de251-a0cf-4808-857e-a2b70fb87f53';
    aetherClient = 'b023ae3c-83c8-4ac5-927a-31ec3cc63552';
    aetherInfoApiUrl = 'https://aetherapi-development.azurewebsites.net';
    aetherInfoClientId = 'edd796ec-4607-451c-9d9f-dcded700bd1f';
    crmGatewayUrl = 'https://crmgateway2-sandbox-dev.azurewebsites.net';
    currentUsersAPIUrl = 'https://websvcdev.azure-api.net/currentusersapi';
    currentUsersClientId = 'adaacddf-622d-43a7-8c89-43e7d383baac';
    appInsights = '271d4ea1-2c54-4eab-aee5-123e6291683e';
    break;
}

export default {
  crmGatewayUrl: crmGatewayUrl,
  imageUrl: window.location.origin.indexOf('localhost') > -1 ? 'https://localhost:44300/ImageUrl' : `${crmGatewayUrl}/crmgateway2/ImageUrl`,
  aetherInfoApiUrl: aetherInfoApiUrl,
  aetherInfoClientId: aetherInfoClientId,
  crmInstance: crmInstance,
  aetherClient: aetherClient,
  appInsights: appInsights,
  apiUrl: `${crmGatewayUrl}/crmgateway2/StudentInformationAPI`,
  commentsApiUrl: `${crmGatewayUrl}/crmgateway2/CommentsAPI`,
  imageUrl: `${crmGatewayUrl}/crmgateway2/ImageUrl`,
  gatewayClientId: gatewayClientId,
  apimClientId: '',
  currentUsersAPIUrl: currentUsersAPIUrl,
  pidm: 0,
  libertyId: 'L00000000',
  contactId: '',
  currentUsersClientId: currentUsersClientId,
  appName: null,
  showSearchArr: ['search', 'events-by-rep', 'dashboards', 'reports', 'feedback', 'declarations', 'registrations', 'switches', 'kanban', 'admin', 'pinnedRecentDashboard', 'opportunities'],
  userPreferences: {},
  allApps: ['General', 'LUO', 'Flames Club', 'LUOA', 'Registrar', 'Ticket Office', 'Donor Engagement'],
  uDevApps: ['Flames Club', 'Ticket Office', 'Donor Engagement']
}